<template>
  <v-card style="border-radius: 30px" height="64" class="d-flex align-end">
    <v-layout justify-end>
      <v-flex shrink class="pb-2">
        <v-btn fab width="100" height="100" :color="color">
          <v-img
            width="50"
            height="50"
            contain
            aspect-ratio="1"
            :src="image"
          ></v-img>
        </v-btn>
      </v-flex>
      <v-flex
        class="d-flex align-end pb-4 pl-3 font-w-500 font-24"
        :style="{ color: color }"
      >
        {{ text }}
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    text: null,
    image: null,
    color: null,
  },
};
</script>

<style></style>
